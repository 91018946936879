import * as React from "react"
import { Helmet } from "react-helmet"

function Seo({ description, lang, title }) {

  const metaDescription = description
  const defaultTitle = title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        }
      ]}
    />
  )
}

export default Seo

// {
//   name: `twitter:card`,
//   content: `summary`,
// },
// {
//   name: `twitter:creator`,
//   content: title,
// },
// {
//   name: `twitter:title`,
//   content: title,
// },
// {
//   name: `twitter:description`,
//   content: metaDescription,
// },
