import React, { useEffect } from "react"

import Seo from "./seo"

import "../css/styles.scss"

const Layout = ({ children, ready }) => {

  useEffect(() => {
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)

    window.addEventListener('resize', () => {
      document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
    });
  })

  return (
    <main className={ready ? "" : "loading"}>
      <Seo description="Records • Publishing • Management • Creative Services" title="Two First Names" lang="en-gb"/>
      {children}
    </main>
  )
}

export default Layout
